/* eslint-disable no-restricted-globals */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getEnvironmentConfig, initSentry } from '@innogy/core/observability';

import { DEPLOY_URL_VALUE, EssentZakelijkAppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

getEnvironmentConfig(DEPLOY_URL_VALUE).then(initSentry).catch(console.error);

document.readyState !== 'loading'
  ? bootstrap()
  : document.addEventListener('DOMContentLoaded', bootstrap);

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(EssentZakelijkAppModule)
    .catch((err) => console.error(err));
}
