import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  inject,
  Input,
  Output,
} from '@angular/core';
import { TrackProductService } from '@innogy/core/analytics';
import type { Offer } from '@innogy/become-a-customer/shared/interfaces';
import { mapOfferToCommerce } from '@innogy/become-a-customer/shared';
import { ENVIRONMENT_CONFIG } from '@innogy/core-config-angular';
import type {
  EnvConfigBrand,
  EnvConfigSegment,
  EnvConfigType,
} from '@innogy/core-config-models';
import { EnvironmentConfig } from '@innogy/core-config-models';
import { MediaObserverService } from '@innogy/shared/responsive-tools';
import { Subject, takeUntil } from 'rxjs';
import { ContractType, ProductDuration } from '@essent/common';
import { ActivatedRoute, Router } from '@angular/router';

import {
  CommodityOfferAlignment,
  CommodityOfferContent,
  type CommodityOfferLayoutType,
} from '../commodity-offers.model';

@Component({
  selector: 'wl-commodity-offer-list',
  templateUrl: './commodity-offer-list.component.html',
  styleUrls: [
    './commodity-offer-list.component.ed.scss',
    './commodity-offer-list.component.essent.scss',
  ],
})
export class CommodityOfferListComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() offers?: Offer[];
  @Input() content?: CommodityOfferContent;
  @Input() showMemberGetMember = false;
  @Input() alignment?: CommodityOfferAlignment;
  @Output() selectOffer = new EventEmitter<Offer>();
  @Output() openMoreInfo = new EventEmitter<Offer>();
  @Output() viewTariffs = new EventEmitter<Offer>();
  brand?: EnvConfigBrand;
  segment?: EnvConfigSegment;
  type?: EnvConfigType;
  computedVariant?: CommodityOfferLayoutType;
  selectedContractType?: ContractType;
  initiallyActiveOfferIds: string[] = [];
  prioritizedOffers: Offer[] = [];
  _showTariffsForAllOffers = false;

  private readonly onDestroy$ = new Subject();

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly envConfig: EnvironmentConfig,
    private readonly mediaObserverService: MediaObserverService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly trackProductService: TrackProductService
  ) {}

  ngOnInit(): void {
    this.brand = this.envConfig.brand;
    this.segment = this.envConfig.segment;
    this.type = this.envConfig.type;

    this.mediaObserverService.deviceInfo$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ isDesktop }) => {
        if (this.alignment === 'vertical' || !isDesktop) {
          this.computedVariant = 'accordion';
        } else {
          this.computedVariant = 'card';
        }

        // Manually trigger change detection
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const newOffers = changes.offers?.currentValue as Offer[];

    if (newOffers && newOffers.length > 0) {
      const activeOfferIndex = Math.floor(newOffers.length / 2);

      /**
       * In the new commodity offer listings, the middle offer shown is the most important. We therefore switch the first offer to the middle.
       * This workaround must be deleted or at least reworked after the new commodity offers permanently replace the existing offer pages.
       * @see: https://jira.essent.nl/jira/browse/CTR-848
       */
      if (newOffers.length >= 2) {
        this.prioritizedOffers = [
          ...newOffers.slice(1, activeOfferIndex + 1),
          newOffers[0],
          ...newOffers.slice(activeOfferIndex + 1),
        ];
      } else {
        this.prioritizedOffers = newOffers;
      }

      // Set first offer (now reordered) as active
      this.initiallyActiveOfferIds = [newOffers[0].offerId];

      // Track impressions of all shown offers
      newOffers.forEach((offer) => {
        const commerceObject = mapOfferToCommerce(offer);
        this.trackProductService.trackImpression(commerceObject);
      });

      // If no contract type is selected, select the query param or the first available contract type
      if (!this.selectedContractType) {
        const contractType =
          this.activatedRoute.snapshot.queryParams[
            'contractType'
          ]?.toUpperCase();
        this.selectedContractType =
          contractType && this.availableContractTypes.includes(contractType)
            ? contractType
            : this.availableContractTypes[0];
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();

    this.router.navigate([], {
      queryParams: {
        contractType: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  isActive(offer: Offer): boolean {
    return this.initiallyActiveOfferIds.includes(offer.offerId);
  }

  get showTariffsForAllOffers() {
    return this._showTariffsForAllOffers && this.computedVariant === 'card';
  }

  get listContainsHighlights() {
    return !!this.offers?.some(
      (offer) => offer.isHighlighted && offer.isHighlightedLabel
    );
  }

  get listContainsIncentives() {
    return !!this.offers?.some((offer) => offer.incentiveId);
  }

  get isEssentConsumerOpen() {
    return (
      this.brand === 'essent' &&
      this.segment === 'consument' &&
      this.type === 'open'
    );
  }

  get isSME() {
    return this.segment === 'zakelijk';
  }

  get isEssentSMEOpen() {
    return this.brand === 'essent' && this.isSME && this.type === 'open';
  }

  get isEssentConsumentRetention() {
    return (
      this.brand === 'essent' &&
      this.segment === 'consument' &&
      this.type === 'mijn'
    );
  }

  get isEnergiedirect() {
    return this.brand === 'ed';
  }

  get isEnergiedirectConsumerOpen() {
    return (
      this.brand === 'ed' &&
      this.segment === 'consument' &&
      this.type === 'open'
    );
  }

  get isEnergiedirectConsumerRetention() {
    return (
      this.brand === 'ed' &&
      this.segment === 'consument' &&
      this.type === 'mijn'
    );
  }

  get availableContractTypes() {
    const orderedContractTypes = [
      ContractType.FIXED,
      ContractType.VARIABLE,
      ContractType.DYNAMIC,
    ];

    const contractTypesSet = Array.from(
      new Set(this.offers?.map((offer) => this.detectOfferContractType(offer)))
    );

    return orderedContractTypes.filter((contractType) =>
      contractTypesSet.some((type) => type === contractType)
    );
  }

  get filteredOffers() {
    if (
      this.isEnergiedirectConsumerRetention ||
      this.isEssentConsumentRetention
    ) {
      return this.prioritizedOffers.filter(
        (offer) =>
          this.detectOfferContractType(offer) === this.selectedContractType
      );
    } else {
      return this.prioritizedOffers;
    }
  }

  detectOfferContractType(offer: Offer): ContractType {
    if (offer.duration === ProductDuration.FLEX && offer.isDynamicProduct) {
      return ContractType.DYNAMIC;
    } else if (
      offer.duration === ProductDuration.FLEX &&
      !offer.isDynamicProduct
    ) {
      return ContractType.VARIABLE;
    } else {
      return ContractType.FIXED;
    }
  }

  changeContractType(contractType: ContractType) {
    this.selectedContractType = contractType;
  }
}
